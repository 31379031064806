<template>
  <div class="menu">
    <header
      class="header"
      :class="{ hoverable: isProjectEntered, fixed: isMobileMenuOpen }"
    >
      <BackButton
        v-if="isProjectEntered"
        class="container project"
        :style="`--project-title: '${title}'`"
      />
      <div v-else class="container basic">
        <ul>
          <li>
            <a href="mailto:nickjeffreystudio@gmail.com" title="Mail">
              <i class="icon icon-mail"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/screen.dawn/"
              target="_blank"
              title="Instagram"
            >
              <i class="icon icon-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href="https://residualdrifter.bandcamp.com/music/"
              target="_blank"
              title="Bandcamp"
            >
              <i class="icon icon-bandcamp"></i>
            </a>
          </li>
          <li>
            <a
              href="https://nickjeffrey.bigcartel.com/"
              target="_blank"
              title="Store"
            >
              <i class="icon icon-cart"></i>
            </a>
          </li>
          <li>
            <router-link to="/about/links" title="More">
              <i class="icon icon-kebab"></i>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="masthead">
        <button
          class="avatar-button"
          :title="themeMsg"
          v-on:click.stop="toggleTheme"
        />
        <MenuButton v-model:open="isMobileMenuOpen" />
      </div>
    </header>
    <main class="main">
      <Tab
        v-for="tab in tabs"
        :key="tab.id"
        :id="tab.id"
        :component="tab.component"
      >
        <template v-if="tab.heading" v-slot:default>
          {{ tab.heading }}
        </template>
      </Tab>
    </main>
    <nav v-if="isMobileMenuOpen" class="nav-menu">
      <ul>
        <li v-for="tab in tabs" :key="tab.id">
          <router-link
            @click="isMobileMenuOpen = !isMobileMenuOpen"
            :to="{ name: tab.id }"
          >
            {{ tab.heading || tab.id }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components,vue/no-unused-vars, no-unused-vars */
import store from "@/store";
import { SET_DARK_THEME } from "@/store/handlers";
import { mapState, mapGetters } from "vuex";
import Tab from "@/components/Tab";
import MenuButton from "@/components/MenuButton";
import BackButton from "@/components/BackButton";

export default {
  components: {
    Tab,
    MenuButton,
    BackButton,
  },
  data: function () {
    return {
      tabs: [
        {
          id: "news",
          component: "Document",
        },
        {
          id: "works",
          component: "Directory",
          heading: "Selected Works",
        },
        {
          id: "misc",
          component: "Directory",
          heading: "Miscellaneous",
        },
        {
          id: "about",
          component: "Directory",
        },
      ],
      socials: [
        {
          id: "Mail",
          icon: "icon-mail",
          href: "mailto:nickjeffrey0@gmail.com",
        },
        {
          id: "Instagram",
          icon: "icon-instagram",
          href: "https://www.instagram.com/nickjeffrey_studio/",
        },
        {
          id: "Bandcamp",
          icon: "icon-bandcamp",
          href: "https://residualdrifter.bandcamp.com/music/",
        },
        {
          id: "Store",
          icon: "icon-cart",
          href: "https://nickjeffrey.bigcartel.com/",
        },
      ],
      isMobileMenuOpen: false,
      isChrome:
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor),
    };
  },
  computed: {
    ...mapState(["group", "section", "project", "darkTheme"]),
    ...mapGetters(["isProjectEntered", "isProjectShown", "isDesktop"]),
    name() {
      return process.env.VUE_APP_NAME;
    },
    themeMsg() {
      return `Switch to ${this.darkTheme ? "light" : "dark"} mode`;
    },
    title() {
      return this.isProjectEntered ? this.project.title : this.name;
    },
  },
  watch: {
    isMobileMenuOpen(val) {
      if (val) {
        document.body.classList.add("no-scroll");
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
  },
  methods: {
    toggleTheme() {
      store.commit(SET_DARK_THEME, !this.darkTheme);
    },
  },
};
</script>

<style>
.header {
  position: relative;
  width: 100%;
  height: 9.6rem;
  border-bottom: 1px solid var(--primary-color);
}

.header .masthead:before {
  content: "Nick Jeffrey";
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  line-height: 9.6rem;
  text-align: center;
  text-transform: uppercase;
}

.header .masthead button {
  display: inline-block;
}

.header .masthead .avatar-button {
  float: left;
  margin: 2.6rem 1.2rem;
  width: 4.4rem;
  height: 4.4rem;
  background-image: url("~@/assets/img/avatars/blackcloud.jpg");
  background-size: cover;
  background-position: center;
  clip-path: circle(50%);
  transition: transform 0.2s ease-in-out;
}

.header .masthead .avatar-button:hover {
  transform: scale(1.15);
}

.header .masthead .menu-button {
  float: right;
}

.header .container {
  display: none;
  height: 100%;
}

.header .container:after {
  content: "Nick Jeffrey";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  opacity: 1;
  pointer-events: none;
}

.header .project:after {
  content: var(--project-title);
}

.header .basic:after {
  content: "Nick Jeffrey";
}

.header .basic ul,
.header .basic:after {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.header .basic:hover:after {
  opacity: 0;
  transform: translateX(-50%) translateY(-460%) rotate(-90deg);
}

.header .basic:hover ul {
  opacity: 1;
  transform: translateY(0%);
}

.header .basic:hover ul li {
  padding: 0.6rem 0;
}

.header .basic ul {
  display: none;
  position: absolute;
  padding-bottom: 1rem;
  width: 100%;
  bottom: 0;
  list-style: none;
  opacity: 0;
  transform: translateY(40%);
}

.header .basic ul li {
  transition: transform 0.2s ease-in-out, padding 0.3s ease-in-out;
}

.header .basic ul li:hover {
  transform: scale(1.15);
}

.header .basic ul li a {
  display: block;
  margin: 0 auto;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 2.4rem;
  text-decoration: none;
}

.header .basic ul li .icon {
  font-size: inherit;
  color: var(--primary-color);
  vertical-align: top;
}

.nav-menu {
  position: fixed;
  margin-top: 9.6rem;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--background-color);
  z-index: 10;
}

.nav-menu:before {
  content: "";
}

.nav-menu ul {
  list-style-type: none;
}

.nav-menu ul li {
  display: inline;
}

.nav-menu ul li a {
  position: relative;
  display: block;
  height: 4.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 4.8rem;
  text-decoration: none;
  text-transform: uppercase;
  padding-left: 0.75em;
  border-bottom: 1px solid var(--primary-color);
  transition: background-color 0.2s ease-in-out;
  box-sizing: border-box;
}

.nav-menu ul li a:hover {
  background-color: var(--secondary-color);
}

@media only screen and (max-width: 768px) {
  .header.fixed {
    position: fixed;
    background-color: var(--background-color);
    z-index: 9999;
  }
}

@media only screen and (min-width: 769px) {
  .header {
    width: 10rem;
    height: 4.8rem;
    line-height: 4.8rem;
    height: 100%;
    padding-left: 0;
    line-height: initial;
    border-bottom: none;
  }

  .header.hoverable {
    transition: background-color 0.2s ease-in-out;
  }

  .header.hoverable:hover {
    /*cursor: url(img/icons/cursor_pointer.png) 6 0, auto;*/
    background-color: var(--secondary-color);
  }

  .header .masthead {
    width: 100%;
    height: auto;
    margin: 2.4rem 0;
  }

  .header .masthead:before {
    display: none;
  }

  .header .masthead .avatar-button {
    position: absolute;
    left: 2.8rem;
    top: 2.4rem;
    display: block;
    margin: 0 auto;
    float: initial;
  }

  .header .masthead .menu-button {
    display: none;
  }

  .header .container {
    display: block;
  }

  .header .basic ul {
    display: block;
  }

  .nav-menu {
    display: none;
  }
}

#base.dark-theme .header .masthead .avatar-button {
  filter: invert(100%);
}
</style>
